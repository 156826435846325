import React, { FC, useEffect, useMemo, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { useConnection } from '../contexts/Application';

type Props = {
  type?: string;
  url?: string;
} & SelectProps;
export const EnumInput: FC<Props> = (props) => {
  const connection = useConnection();
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    connection
      .get(props.url ?? `admin/masterdata/${props.type}/for/select`)
      .then((value: any[]) => value.sort((a, b) => a.label.localeCompare(b.label)))
      .then(setOptions)
      .finally(() => setLoading(false));
  }, [connection, props.type, props.url]);
  return (
    <Select
      loading={loading}
      {...props}
      options={options}
      filterOption={(inputValue, option) =>
        `${option?.label ?? option?.value ?? ''}`.toLowerCase().includes(inputValue.toLowerCase())
      }
    />
  );
};
