import React, { FC, useRef } from 'react';
import { Button, Table } from 'antd';
import { AdminTitle } from './AdminPanelContext';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import DataEditModal, { DataEditModalInstance } from '../../components/DataEditModal';
import { useDataTable } from '../../components/DataTable';
import {FormSchema} from "@sezenta/antd-schema-form";

const schema: FormSchema = [
  {
    id: 'firstname',
    name: 'First Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'First name is required',
        },
      ],
    },
  },
  {
    id: 'lastname',
    name: 'Last Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'Last name is required',
        },
      ],
    },
  },
  {
    id: 'email',
    name: 'Email',
    type: 'email',
    options: {
      rules: [
        {
          required: true,
          message: 'Email is required',
        },
      ],
    },
  },
  // {
  //   id: 'role',
  //   name: 'Role',
  //   type: 'select',
  //   props: {
  //     options: {
  //       admin: 'Admin',
  //       'insureme-operations': 'InsureMe Operations',
  //       'business-operations': 'Business Operations',
  //       'regional-manager': 'Regional Manager',
  //       supervisor: 'Supervisor',
  //       agent: 'Agent',
  //     },
  //   },
  //   options: {
  //     rules: [
  //       {
  //         required: true,
  //         message: 'Role is required',
  //       },
  //     ],
  //   },
  // },
];
const createSchema: FormSchema = [
  ...schema,
  {
    id: 'password',
    name: 'Password',
    type: 'password',
    options: {
      rules: [
        {
          required: true,
          message: 'Password is required',
        },
      ],
    },
  },
];

export const AdminUsersPage: FC = () => {
  const userEditModal = useRef<DataEditModalInstance>(null);
  const { tableProps } = useDataTable('admin', {
    eventConfig: { name: 'ADMIN', updates: ['_id'], add: {} },
  });
  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstname',
      sorter: true,
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastname',
      sorter: true,
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: '_id',
      render: (_: string, record: any) => (
        <div>
          <Button
            shape="circle"
            type="link"
            onClick={() =>
              userEditModal.current?.edit(
                record,
                schema,
                'Edit Admin',
                `admin/${record._id}`,
                (r) => {
                  return r;
                },
              )
            }>
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <AdminTitle>Users</AdminTitle>
      <div className="flex justify-end gap-4">
        <Button
          type="primary"
          onClick={() =>
            userEditModal.current?.create(createSchema, `Create Admin`, `auth/admin/register`)
          }>
          <PlusOutlined />
          Add New
        </Button>

      </div>
      <Table columns={columns} {...tableProps} />
      <DataEditModal ref={userEditModal} type="ADMIN" />
    </div>
  );
};
