import React, { FC, useCallback, useState } from 'react';
import { Button, Card, Divider, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useConnection } from '../../contexts/Application';
import Form from '@sezenta/antd-schema-form';

export const Login: FC = () => {
  const [form] = Form.useForm();
  const connection = useConnection();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const login = useCallback(
    (values: any) => {
      setLoading(true);
      connection
        .post('auth/admin/login', {
          email: values.username,
          password: values.password,
          version: '1.0.0',
        })
        .then((value) => {
          if (value.status === 'SUCCESS') {
            message.success('Login Success!');
            connection.setToken(value.token);
            navigate('/admin/admin-users');
          } else {
            message.error(value.message);
          }
        })
        .catch((err) => console.log('ERR', err))
        .finally(() => setLoading(false));
    },
    [connection, navigate],
  );

  return (
    <div className="flex items-center justify-center h-screen">
      {loading ? (
        <div>
          <Spin spinning={loading} />
        </div>
      ) : (
        <Card className="shadow-md flex-col">
          <div className="text-2xl text-center">Login</div>
          <Divider />
          <Form form={form} layout={'vertical'} onFinish={login}>
            <Form.Items
              schema={[
                { id: 'username', name: 'Email Address', type: 'email' },
                { id: 'password', name: 'Password', type: 'password' },
              ]}
            />
          </Form>
          <Divider />
          <Button onClick={() => form.submit()} type="primary" className="w-full">
            Login
          </Button>
        </Card>
      )}
    </div>
  );
};
