import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu, theme } from 'antd';
import { UserOutlined, ThunderboltOutlined, TableOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAdminPanelContext } from './AdminPanelContext';
import { useConnection } from '../../contexts/Application';

const { Header, Content, Footer, Sider } = Layout;

export const Admin: FC = () => {
  const navigation = useNavigate();
  const ctx = useAdminPanelContext();
  const connection = useConnection();
  const [masterData, setMasterData] = useState<any[]>([]);
  useEffect(() => {
    connection.get('admin/masterdata/1', { size: 100 }).then((res) => setMasterData(res.data));
  }, [connection]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="h-screen">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['4']}
          items={[
            {
              key: 'admin-users',
              icon: <UserOutlined />,
              label: 'Users',
              onClick: () => navigation('/admin/admin-users'),
            },
            {
              key: 'admin-doctors',
              icon: <UserOutlined />,
              label: 'Consultants',
              onClick: () => navigation('/admin/admin-doctors'),
            },
            {
              key: 'admin-patients',
              icon: <UserOutlined />,
              label: 'Patients',
              onClick: () => navigation('/admin/admin-patients'),
            },
            {
              key: 'master-data',
              icon: <TableOutlined />,
              label: 'Master Data',
              children: masterData.map((md) => ({
                key: `master-data-${md._id}`,
                icon: <TableOutlined />,
                label: md.name.en,
                onClick: () => navigation(`/admin/master-data/${md._id}`),
              })),
            },
          ]}
        />
      </Sider>
      <Layout className="h-screen">
        <Header style={{ background: colorBgContainer }}>
          <h2>{ctx.title}</h2>
        </Header>
        <Content className="p-4  overflow-y-scroll min-h-fit">
          <div className="bg-white p-4">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
