import React, { FC, useRef, useEffect, useState } from 'react';
import { Button, Table, Modal, Form, Upload, message } from 'antd';
import { AdminTitle } from './AdminPanelContext';
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { FormSchema } from '@sezenta/antd-schema-form';
import DataEditModal, { DataEditModalInstance } from '../../components/DataEditModal';
import { useDataTable } from '../../components/DataTable';
import { useConnection, useEvents } from '../../contexts/Application';
import FileUploadEx from '../../components/FileUpload';
import * as XLSX from 'xlsx';
const schema: FormSchema = [
  {
    id: 'firstname',
    name: 'First Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'First name is required',
        },
      ],
    },
  },
  {
    id: 'lastname',
    name: 'Last Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'Last name is required',
        },
      ],
    },
  },
  {
    id: 'doctorcode',
    name: 'Code',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'First name is required',
        },
      ],
    },
  },
];

const schemaU: FormSchema = [
  {
    id: 'firstname',
    name: 'First Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'First name is required',
        },
      ],
    },
  },
  {
    id: 'lastname',
    name: 'Last Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'Last name is required',
        },
      ],
    },
  },
  // {
  //   id: 'upload',
  //   name: 'file',
  //   type: 'button',
  //   options: {
  //     buttonProps: {
  //       type: 'primary',
  //       onClick: () => {
  //         // Add your download logic here
  //         console.log('Downloading template...');
  //       },
  //     },
  //   },
  // },
];
const generateEmptyExcel = () => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([{ FirstName: '', LastName: '', DoctorCode: '' }]);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'DoctorList');
  XLSX.writeFile(workbook, 'DoctorList.xlsx');
};
export const AdminDoctorPage: FC = () => {
  const events = useEvents();
  const connection = useConnection();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const userEditModal = useRef<DataEditModalInstance>(null);
  const { tableProps, reload, setFilter } = useDataTable('admin/doctors', {
    eventConfig: {
      name: 'DOCTOR',
      updates: ['_id'],
      add: {},
    },
    defaultSort: { column: 'createdTime', direction: 'desc' },
  });

  // useEffect(() => {
  //   const handler = () => {
  //     reload();
  //   };
  //   events.on('DOCTOR', handler);
  //   return () => {
  //     events.off('DOCTOR', handler);
  //   };
  // }, [events, reload]);

  const handleUpload = async (url: string, name: string, data?: any) => {
    try {
      if (url) {
        message.success('File uploaded successfully!');
        setUploadModalVisible(false);
        connection
          .post('admin/doctors/upload', { name })
          .then((res) => {
            if (res.status === 'Success') {
              message.success(res.message);
              reload();
              events.emit('DOCTOR', { action: 'CREATE', data: res.data });
            } else {
              message.error(res.message);
            }
          })
          .catch();
      }
    } catch (error) {
      console.error('Error while uploading file:', error);
      message.error('An error occurred while uploading the file.');
    }
  };

  const handleRemove = async (recordId: string) => {
    try {
      await connection.delete(`admin/doctor/${recordId}`).then((res) => {
        if (res.status === 200) {
          console.log(`Record with ID ${recordId} successfully removed.`);
          events.emit('DOCTOR', { action: 'REMOVE', data: recordId });
        } else {
          console.error(`Failed to remove record with ID ${recordId}`);
        }
      });
    } catch (error) {
      console.error('Error while removing record:', error);
    }
  };

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstname',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastname',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'doctorcode',
      title: 'Code',
      dataIndex: 'doctorcode',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    // {
    //   key: 'createdDate',
    //   title: 'Date',
    //   dataIndex: 'createdDate',
    //   render: (createdDate: string) => {
    //     const date = new Date(createdDate);
    //     return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    //   },
    //   sorter: (a: any, b: any) => {
    //     const dateA = new Date(a.createdDate).getTime();
    //     const dateB = new Date(b.createdDate).getTime();
    //     return dateA - dateB;
    //   },
    //   sortDirections: ['ascend', 'descend'],
    // },

    {
      key: 'actions',
      title: 'Actions',
      dataIndex: '_id',
      render: (_: string, record: any) => (
        <div>
          <Button
            shape="circle"
            type="link"
            onClick={() =>
              userEditModal.current?.edit(
                record,
                schema,
                'Edit Doctor',
                `admin/doctors/${record._id}`,
                (updatedData) => {
                  return updatedData;
                },
              )
            }>
            <EditOutlined />
          </Button>
          {/*<Button*/}
          {/*  shape="circle"*/}
          {/*  type="link"*/}
          {/*  onClick={() =>*/}
          {/*    userEditModal.current?.edit(*/}
          {/*      record,*/}
          {/*      schema,*/}
          {/*      'Delete Doctor',*/}
          {/*      `admin/delete/doctor/${record._id}`,*/}
          {/*      (updatedData) => {*/}
          {/*        return updatedData;*/}
          {/*      },*/}
          {/*    )*/}
          {/*  }>*/}
          {/*  <DeleteOutlined />*/}
          {/*</Button>*/}
          <Button
            shape="circle"
            type="link"
            onClick={() => {
              Modal.confirm({
                title: 'Confirm Delete',
                content: 'Are you sure you want to delete this record?',
                onOk: async () => {
                  await handleRemove(record._id);
                },
              });
            }}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <AdminTitle>Consultant</AdminTitle>
      <div className="flex justify-end gap-4">
        <Button type="primary" onClick={() => setUploadModalVisible(true)}>
          <UploadOutlined />
          Upload Doctor List
        </Button>

        <Modal
          title="Upload Doctor"
          visible={uploadModalVisible}
          onCancel={() => setUploadModalVisible(false)}
          footer={null}>
          <Form name="downloadDoctor">
            <Form.Item
              label="Download"
              name="file"
              rules={[{ required: true, message: 'Please download a CSV file' }]}>
              <Button type="primary" icon={<DownloadOutlined />} onClick={generateEmptyExcel}>
                Download
              </Button>
            </Form.Item>
          </Form>

          <Form name="uploadDoctor">
            <Form.Item
              label="Upload CSV"
              name="file"
              rules={[{ required: true, message: 'Please upload a CSV file' }]}>
              <FileUploadEx onChange={(url, name) => handleUpload(url as string, name as string)}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </FileUploadEx>
            </Form.Item>
          </Form>
        </Modal>
        {/*<Modal*/}
        {/*  title="Upload Doctor"*/}
        {/*  visible={uploadModalVisible}*/}
        {/*  onCancel={() => setUploadModalVisible(false)}*/}
        {/*  footer={null}>*/}
        {/*  <FileUploadEx*/}
        {/*    showEdit={false}*/}
        {/*    onChange={handleUpload}*/}
        {/*    accept=".csv"*/}
        {/*    style={{ marginTop: 16 }}*/}
        {/*  />*/}
        {/*</Modal>*/}
        <Button
          type="primary"
          onClick={() => userEditModal.current?.create(schema, `Create Doctor`, `admin/doctor`)}>
          <PlusOutlined />
          Add New
        </Button>
      </div>

      <Table columns={columns as any} {...tableProps} />
      <DataEditModal ref={userEditModal} type="DOCTOR" />
    </div>
  );
};
