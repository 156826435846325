import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, message, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useConnection } from '../../contexts/Application';
import DataEditModal, { DataEditModalInstance } from '../../components/DataEditModal';
import { AdminTitle } from '../Admin/AdminPanelContext';
import { useDataTable } from '../../components/DataTable';

export const MasterDataPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { tableProps, setFilter } = useDataTable(`admin/masterdata/${id}`, {
    eventConfig: { name: 'MASTER_DATA', add: { type: id }, updates: ['type', '_id'] },
  });
  const editModal = useRef<DataEditModalInstance>(null);
  const [masterData, setMasterData] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const connection = useConnection();

  useEffect(() => {
    connection.get(`admin/masterdata/1/${id}`).then(setMasterData);
  }, [connection, id]);

  const schema = useMemo(() => {
    let custom = undefined;
    try {
      custom = JSON.parse(masterData?.data?.schema ?? '[]');
      if (!Array.isArray(custom)) {
        console.log('Failed to parse schema', masterData?.data.schema, 'Schema is not an array');
        custom = undefined;
      }
    } catch (e) {
      console.log('Failed to parse schema', masterData?.data?.schema, e);
    }
    const createSchema = [
      {
        id: 'name',
        type: {
          object: [
            {
              type: 'string',
              id: 'en',
              name: 'Name (English)',
              options: {
                rules: [{ required: true, message: 'English name is required' }],
              },
            },
            {
              type: 'string',
              id: 'si',
              name: 'Name (Sinhala)',
            },
            {
              type: 'string',
              id: 'ta',
              name: 'Name (Tamil)',
            },
          ],
        },
      },
    ];
    if (custom) {
      createSchema.push({
        id: 'data',
        type: {
          object: custom,
        },
      });
    }
    const editSchema = [
      ...createSchema,
      {
        id: 'status',
        type: 'select',
        name: 'Status',
        props: {
          options: ['Active', 'Inactive'],
        },
      },
    ];
    return { createSchema, editSchema };
  }, [masterData?.data?.schema]);

  const columns = useMemo(
    () => [
      {
        key: '_id',
        title: 'ID',
        dataIndex: '_id',
        sorter: true,
      },
      {
        key: 'name.en',
        title: 'Name',
        dataIndex: 'name',
        render: (value: any) => value.en,
        sorter: true,
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
      },
      {
        key: 'actions',
        title: 'Actions',
        dataIndex: '_id',
        render: (_: any, record: any) => (
          <div>
            <Button
              shape="circle"
              type="link"
              onClick={() =>
                editModal.current?.edit(
                  record,
                  schema.editSchema,
                  `Edit ${masterData?.name.en}`,
                  `admin/masterdata/${record.type}/${record._id}`,
                )
              }>
              <EditOutlined />
            </Button>
          </div>
        ),
      },
    ],
    [masterData?.name.en, schema.editSchema],
  );

  const publish = useCallback(() => {
    setLoading(true);
    connection
      .post('admin/masterdata/generate/s3/files')
      .then(() => message.success('Successfully published master data changes'))
      .catch(() => message.error('Failed to publish master data changes'))
      .finally(() => setLoading(false));
  }, [connection]);

  return (
    <div className="flex flex-col gap-4">
      <AdminTitle>{masterData?.name.en ?? ''}</AdminTitle>
      <div className="flex justify-end gap-4">
        <Button loading={loading} type="default" onClick={publish}>
          Publish Changes
        </Button>
        <Button
          type="primary"
          onClick={() =>
            editModal.current?.create(
              schema.createSchema,
              `Create ${masterData?.name?.en}`,
              `admin/masterdata/${masterData._id}`,
            )
          }>
          <PlusOutlined />
          Add New
        </Button>
      </div>
      <div className="flex justify-end">
        <Input.Search
          allowClear
          className="w-full max-w-[400px]"
          placeholder="Search agent"
          onSearch={(value) => setFilter({ search: value })}
        />
      </div>
      <Table columns={columns} rowKey={'_id'} {...tableProps} />
      <DataEditModal ref={editModal} type="MASTER_DATA" />
    </div>
  );
};
