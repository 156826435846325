import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Grid, Input, Modal, Spin, Upload, Image } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { CloseOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { useConnection } from '../contexts/Application';
import ImgCrop, { ImgCropProps } from './AntdImgCrop';
import * as querystring from 'querystring';

// import ImageViewer from './ImageViewer';
const { useBreakpoint } = Grid;
export type FileUploadExProps = {
  dataUrl?: boolean;
  value?: string;
  allowClear?: boolean;
  showEdit?: boolean;
  imageStyle?: CSSProperties;
  editButtonStyle?: CSSProperties;
  onChange?: (url?: string, name?: string) => void;
  type?: string;
  accept?: string;
  acceptSize?: number;
  beforeUpload?: (file: RcFile, list: RcFile[]) => boolean;
  image?: Omit<ImgCropProps, 'children'> & { crop?: boolean; width?: number; height?: number };
  s3Key?: string;
  placeholder?: string;
  id?: string;
  className?: string;
  style?: CSSProperties;
  justifyContent?: CSSProperties;
};

const FileDraggerEmpty: FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <UploadOutlined />
      <span style={{ fontSize: 14, fontWeight: 500 }}>Drag &amp; Drop</span>
      <span style={{ fontSize: 12, color: '#9999A3' }}>File types: .jpeg, .png</span>
      <span style={{ fontSize: 12, color: '#9999A3' }}>No larger than 1MB</span>
      <Button style={{ height: 33, fontSize: 14 }} type={'primary'} size={'small'}>
        Upload Now
      </Button>
    </div>
  );
};

const FileUploadEx: React.FC<PropsWithChildren<FileUploadExProps>> = (props) => {
  const fileUrl = useRef<string | undefined>(props.value);
  const fileName = useRef<string | undefined>();
  const { accept, acceptSize } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const connection = useConnection();
  const bp = useBreakpoint();

  const checkFile = useCallback(
    (file: any) => {
      if (accept) {
        const extension = `.${file.name.split('.').reverse()[0].toLowerCase()}`;
        if (
          accept
            .split(',')
            .map((a) => a.trim())
            .indexOf(extension) === -1
        ) {
          Modal.error({
            title: 'Invalid file selected',
            content: `Please select a valid file with extension ${accept}`,
          });
          return false;
        }
      }

      if (acceptSize) {
        if (file.size > acceptSize * 1024 * 1024) {
          Modal.error({
            title: 'Invalid file selected',
            content: `Please choose a file size no more than ${acceptSize} Mb`,
          });
          return false;
        }
      }

      return true;
    },
    [accept, acceptSize],
  );

  const req = useCallback(
    (info: any) => {
      if (!checkFile(info.file)) {
        return;
      }

      if (props.dataUrl) {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            // convert image file to base64 string
            console.log('RESSSSSSSSSSS', reader.result);
            fileUrl.current = reader.result as string;
            info.file.status = 'done';
            info.onSuccess(undefined, info);
          },
          false,
        );
        reader.readAsDataURL(info.file);
        return;
      }

      setLoading(true);
      connection
        .post('files/pre-signed-url', {
          fileName: info.file.name,
          mimeType: info.file.type,
          mask: true,
        })
        .then((response) => {
          const value = response;
          const urlPath = value.upload.substring(0, value.upload.indexOf('?'));
          const query = value.upload.substring(value.upload.indexOf('?') + 1);
          console.log('query', query);
          const url: any = querystring.parse(query);
          fileUrl.current = value.download;
          fileName.current = value?.name;
          const xhr = new XMLHttpRequest();
          const awsSecurityToken = url['x-amz-security-token'];
          delete url['x-amz-security-token'];
          console.log('sss', url);
          xhr.open('PUT', `${urlPath}?${querystring.encode(url)}`, true);
          console.log('AAA', awsSecurityToken);
          if (awsSecurityToken) {
            xhr.setRequestHeader('x-amz-security-token', awsSecurityToken);
          }
          xhr.onprogress = (e) => {
            // info.onProgress({ percent: (e.loaded / e.total) * 100 });
            info.onProgress(e);
          };
          xhr.onabort = () => {
            info.onError(new Error('aborted'), undefined, info.file);
          };
          xhr.onerror = () => {
            info.onError(new Error('error'), undefined, info.file);
          };

          xhr.onloadstart = () => {
            info.onProgress(0);
          };
          xhr.onload = (event) => {
            if (xhr.status === 200) {
              info.onSuccess(event, info);
            } else {
              info.onError(new Error(`error ${xhr.status}`));
            }
          };
          xhr.setRequestHeader('Content-Type', info.file.type);
          xhr.send(info.file);
        })
        .catch(() => setLoading(false));
    },
    [checkFile, connection, props.dataUrl],
  );

  const onChange = useCallback(
    (info: UploadChangeParam) => {
      console.log('FFFFFFFFFFFFFF', info.file);
      if (info.file.status === 'done') {
        props.onChange?.(fileUrl.current, fileName.current);
        setLoading(false);
      } else if (info.file.status !== 'uploading') {
        setLoading(false);
      }
    },
    [props],
  );

  const [w, h] = useMemo(() => {
    const width = props.image?.width ?? 128;
    const height = props.image?.height ?? 128;
    if (width > 128) {
      return [128, (128 / width) * height];
    }
    return [width, height];
  }, [props.image?.height, props.image?.width]);

  const renderUploader = useCallback(() => {
    const { type } = props;
    const prp: any = { ...props };
    delete prp.accept;
    delete prp.showUploadList;
    delete prp.onChange;
    delete prp.customRequest;
    delete prp.beforeUpload;
    if (type === 'drag') {
      return (
        <Upload.Dragger
          {...prp}
          id={props.id}
          className={props.className}
          style={props.style}
          showUploadList={false}
          onChange={onChange}
          customRequest={req}
          accept={props.accept}
          beforeUpload={props.beforeUpload && props.beforeUpload}>
          <Spin spinning={loading}>
            {props.children ? (
              props.children
            ) : props.image ? (
              fileUrl.current ? (
                <Image
                  preview={false}
                  style={{ marginBottom: -24, marginTop: -16, ...props.imageStyle }}
                  alt="Upload"
                  width={props.image.width ?? 100}
                  height={props.image.height ?? props.image.width ?? 100}
                  src={fileUrl.current}
                />
              ) : (
                <FileDraggerEmpty />
              )
            ) : (
              <span>{fileUrl.current}</span>
            )}
          </Spin>
          {props.value && props.allowClear !== false && (
            <Button
              danger
              shape={'circle'}
              size={'small'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                fileUrl.current = undefined;
                fileName.current = undefined;
                props.onChange?.(undefined, undefined);
              }}
              style={{
                position: 'absolute',
                top: -15,
                right: -15,
                minWidth: 30,
                minHeight: 30,
                maxWidth: 30,
                maxHeight: 30,
                padding: 0,
              }}>
              <CloseOutlined />
            </Button>
          )}
          {props.showEdit && (
            <Button
              shape={'circle'}
              size={'small'}
              style={{
                position: 'absolute',
                bottom: -15,
                right: -15,
                minWidth: 30,
                minHeight: 30,
                maxWidth: 30,
                maxHeight: 30,
                padding: 0,
                ...props.editButtonStyle,
              }}>
              <EditOutlined />
            </Button>
          )}
        </Upload.Dragger>
      );
    }
    return (
      <Upload
        {...props}
        id={props.id}
        className={props.className}
        style={props.style}
        name="avatar"
        action={''}
        showUploadList={false}
        customRequest={req}
        accept={props.accept}
        onChange={onChange}
        type={'select'}>
        {props.children && props.children}
        {!props.children && (
          <Input.Search
            placeholder={props.placeholder}
            enterButton={<UploadOutlined />}
            loading={loading}
            value={fileUrl.current?.split('/').reverse()[0]}
          />
        )}
      </Upload>
    );
  }, [loading, onChange, props, req]);
  if (props.image && props.image.crop) {
    return (
      <ImgCrop
        rotate={true}
        shape={'rect'}
        minZoom={0.5}
        beforeCrop={checkFile}
        cropperProps={{ restrictPosition: false, objectFit: 'contain' }}
        // aspect={
        //   props.image?.width && props.image?.height ? props.image?.width / props.image?.height : 1
        // }
        {...props.image}>
        {renderUploader()}
      </ImgCrop>
    );
  }
  return renderUploader();
};

export default FileUploadEx;
