'use client';
import React, { FC, PropsWithChildren } from 'react';
import {
  AntdSchemaFormAdapters,
  AntdSchemaFormValidationAdapters,
} from '@sezenta/antd-schema-form-adapters';
import Form from '@sezenta/antd-schema-form';
import { EnumInput } from './EnumInput';
import FileUploadEx from './FileUpload';

export const FormConfigProvider: FC<PropsWithChildren> = (props) => {
  return (
    <Form.ConfigProvider
      adapters={{
        ...AntdSchemaFormAdapters,
        enum: EnumInput,
        image: FileUploadEx,
      }}
      validators={[...AntdSchemaFormValidationAdapters]}>
      {props.children as any}
    </Form.ConfigProvider>
  );
};
