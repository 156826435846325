import { useCallback, useEffect, useRef, useState } from 'react';
import { useConnection, useEvents } from '../contexts/Application';
import { ConnectionApi } from '../contexts/ApplicationContext';

const searchData = (
  path: string,
  connection: ConnectionApi,
  page: number,
  size: number,
  setDataSource: (data: any[]) => void,
  setLoading: (loading: boolean) => void,
  setTotal: (total: number) => void,
  search: any,
  sortColumn: string,
  sortDirection: string,
) => {
  setLoading(true);
  connection
    .get(path, { page, limit: size, sortColumn, sortDirection, ...(search ?? {}) })
    .then((value) => {
      setDataSource(value.data);
      setTotal(value.total);
    })
    .finally(() => setLoading(false));
};
export const useDataTable = (
  path: string,
  options?: {
    eventConfig?: { name: string; updates: string[]; add: Record<string, any> };
    filter?: any;
    search?: boolean;
    beforeSearch?: (query?: any) => any;
    defaultSort?: { column?: string; direction?: 'asc' | 'desc' };
  },
) => {
  const filter = options?.filter;
  const [searchEnabled, setSearchEnabled] = useState<boolean>(options?.search !== false);
  const eventConfig = options?.eventConfig;
  const [dataSource, setDataSource] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<any>(filter);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const events = useEvents();
  const connection = useConnection();
  const filterRef = useRef<any>(filter);
  useEffect(() => {
    console.log('SEARCH:', filter, path);
    searchData(path, connection, 1, 10, setDataSource, setLoading, setTotal, search, '_id', 'asc');
  }, [connection, filter, path, search]);

  // const onPaginationChange = useCallback(
  //   (page: number, pageSize: number) => {
  //     setCurrentPage(page);
  //     setPageSize(pageSize);
  //     searchData(
  //       path,
  //       connection,
  //       page,
  //       pageSize,
  //       setDataSource,
  //       setLoading,
  //       setTotal,
  //       search,
  //       sortColumn,
  //       sortDirection,
  //     );
  //   },
  //   [connection, path, search, sortColumn, sortDirection],
  // );

  useEffect(() => {
    if (!eventConfig) {
      return;
    }

    const handler = (value: any) => {
      const { action, data } = value;
      console.log('AAAAA', value);
      if (action === 'UPDATE') {
        const index = dataSource.findIndex(
          (v: any) => eventConfig.updates.filter((x) => v[x] !== data[x]).length === 0,
        );
        if (index !== -1) {
          const newDs = [...dataSource];
          newDs[index] = data;
          setDataSource(newDs);
        }
      } else if (action === 'REMOVE') {
        const newData = dataSource.filter((val: any) => val._id !== data);
        setDataSource(newData);
      } else {
        const newData = Array.isArray(data) ? data : [data];

        for (const item of newData) {
          if (Object.entries(eventConfig.add).filter((x) => item[x[0]] !== x[1]).length === 0) {
            const newDs = [item, ...dataSource];
            if (newDs.length > pageSize) {
              setDataSource(newDs.slice(0, pageSize));
            } else {
              setDataSource(newDs);
            }
          }
        }
      }
    };
    if (eventConfig) {
      events.on(eventConfig.name, handler);
    }
    return () => {
      if (eventConfig) {
        events.off(eventConfig.name, handler);
      }
    };
  }, [events, eventConfig, dataSource, pageSize]);

  const onChange = useCallback(
    (pagination1: any, filters: any, sorter: any) => {
      console.log('ONCHANGE', pagination1, sorter);
      setCurrentPage(pagination1.page);
      setPageSize(pagination1.pageSize);
      // setSortColumn(sorter?.columnKey);
      // setSortDirection(sorter?.order === 'descend' ? 'desc' : 'asc');

      searchData(
        path,
        connection,
        pagination1.current,
        pagination1.pageSize,
        setDataSource,
        setLoading,
        setTotal,
        search,
        sorter?.columnKey,
        sorter?.order === 'descend' ? 'desc' : 'asc',
      );
      // if (sorter) {
      //   console.log('SORTER', sorter);
      //   if (sorter.field) {
      //
      //   }
      // } else {
      //   console.log('SORTER NO', (sorter as any).field);
      //   searchData(
      //     path,
      //     connection,
      //     1,
      //     pageSize,
      //     setDataSource,
      //     setLoading,
      //     setTotal,
      //     search,
      //     sortColumn,
      //     sortDirection,
      //   );
      // }
    },
    [connection, path, search],
  );
  const reload = useCallback(() => {
    if (!searchEnabled) {
      return;
    }
    setSearchEnabled(false);
    setTimeout(() => {
      setSearchEnabled(true);
    }, 500);
  }, [searchEnabled]);

  const setFilter = useCallback((val: any) => {
    setSearch(val);
    filterRef.current = val;
  }, []);

  const updateFilter = useCallback((val: any) => {
    const newFilter = { ...(filterRef.current ?? {}), ...val };
    setSearch(newFilter);
    filterRef.current = newFilter;
  }, []);
  return {
    reload,
    filter: search,
    setFilter: setFilter,
    updateFilter,
    tableProps: {
      onChange,
      dataSource,
      loading,
      pagination: {
        total,
        current: currentPage,
        pageSize,
        // onChange: onPaginationChange,
        showSizeChanger: true,
      },
    },
  };
};
