import React, { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

export type AdminPanelContextData = {
  title: React.ReactNode;
  setTitle: (title: React.ReactNode) => void;
};

export const AdminPanelContext = React.createContext<AdminPanelContextData>({} as any);

export const AdminPanelContextProvider: FC<PropsWithChildren> = (props) => {
  const [title, setTitle] = useState<React.ReactNode>('');
  return (
    <AdminPanelContext.Provider value={{ title, setTitle }}>
      {props.children}
    </AdminPanelContext.Provider>
  );
};

export const useAdminPanelContext = () => useContext(AdminPanelContext);

export const AdminTitle: FC<PropsWithChildren> = (props) => {
  const ctx = useAdminPanelContext();
  useEffect(() => {
    ctx.setTitle(props.children);
  });
  return <></>;
};
