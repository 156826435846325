import React, {FC, useEffect, useRef, useState} from 'react';
import { Button, Modal, Table } from 'antd';
import { AdminTitle } from './AdminPanelContext';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { FormSchema } from '@sezenta/antd-schema-form';
import DataEditModal, { DataEditModalInstance } from '../../components/DataEditModal';
import { useDataTable } from '../../components/DataTable';
import {useConnection} from "../../contexts/Application";
import events from "events";

const schema: FormSchema = [
  {
    id: 'firstname',
    name: 'First Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'First name is required',
        },
      ],
    },
  },
  {
    id: 'lastname',
    name: 'Last Name',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'Last name is required',
        },
      ],
    },
  },
  {
    id: 'patientscode',
    name: 'Code',
    type: 'string',
    options: {
      rules: [
        {
          required: true,
          message: 'First name is required',
        },
      ],
    },
  },
];

export const AdminPatient: FC = () => {
  const [patients, setPatients] = useState<any[]>([]);
  const connection = useConnection();
  const userEditModal = useRef<DataEditModalInstance>(null);
  const { tableProps, reload } = useDataTable('admin/patients', {
    eventConfig: { name: 'Patient', updates: ['_id'], add: {} },
    defaultSort: { column: 'createdTime', direction: 'desc' },
  });


  const handleRemove = async (recordId: string) => {
    try {
      const response = await connection.delete(`admin/patient/${recordId}`);

      if (response.ok) {
        console.log(`Record with ID ${recordId} successfully removed.`);
        reload();
      } else {
        const errorMessage = await response.text();
        console.error(`Failed to remove record with ID ${recordId}. Error: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error while removing record:', error);
    }
  };

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstname',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastname',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'patientscode',
      title: 'Code',
      dataIndex: 'patientscode',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    // {
    //   key: 'createdDate',
    //   title: 'Date',
    //   dataIndex: 'createdDate',
    //   render: (createdDate: string) => {
    //     const date = new Date(createdDate);
    //     return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    //   },
    //
    //   sorter: (a: any, b: any) => {
    //     const dateA = new Date(a.createdDate).getTime();
    //     const dateB = new Date(b.createdDate).getTime();
    //     return dateA - dateB;
    //   },
    //   sortDirections: ['ascend', 'descend'],
    // },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: '_id',
      render: (_: string, record: any) => (
        <div>
          <Button
            shape="circle"
            type="link"
            onClick={() =>
              userEditModal.current?.edit(
                record,
                schema,
                'Edit Patient',
                `admin/patients/${record._id}`,
                (r) => {
                  return r;
                },
              )
            }>
            <EditOutlined />
          </Button>
          <Button
            shape="circle"
            type="link"
            onClick={() => {
              Modal.confirm({
                title: 'Confirm Delete',
                content: 'Are you sure you want to delete this record?',
                onOk: async () => {
                  await handleRemove(record._id);
                },
              });
            }}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <AdminTitle>Patient</AdminTitle>
      <div className="flex justify-end gap-4">
        <Button
          type="primary"
          onClick={() => userEditModal.current?.create(schema, `Create Doctor`, `admin/patient`)}>
          <PlusOutlined />
          Add New
        </Button>
      </div>
      <Table columns={columns as any} {...tableProps} />
      <DataEditModal ref={userEditModal} type="Patient" />
    </div>
  );
};
