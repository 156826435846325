import React, { FC, useEffect, useState } from 'react';
import { useConnection } from '../../contexts/Application';
import { Spin } from 'antd';

export const TermsAndConditions: FC = () => {
  const connection = useConnection();
  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    connection
      .get('admin/masterdata/by-name/Static Page/Terms and Conditions')
      .then((value) => setContent(value.data.template.html))
      .catch((reason) => console.log('Err', reason))
      .finally(() => setLoading(false));
  }, [connection]);
  return (
    <div style={{ display: 'flex', minHeight: '100vh', justifyContent: 'center' }}>
      <Spin spinning={loading} style={{ width: '100%', maxWidth: 1200, minHeight: '100vh' }}>
        <div
          style={{ width: '100%', maxWidth: 1200, minHeight: '100vh' }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Spin>
    </div>
  );
};
