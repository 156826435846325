import React, {
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { message, Modal } from 'antd';
import { useConnection, useEvents } from '../contexts/Application';
import Form, {setValidationErrors} from '@sezenta/antd-schema-form';

export type DataEditModalInstance = {
  edit: (
    item: any,
    schema: any,
    title: string,
    url: string,
    prepare?: (record: any) => any,
  ) => void;
  create: (schema: any, title: string, url: string, prepare?: (record: any) => any) => void;
};

type Props = {
  type: string;
};

const DataEditModal: ForwardRefRenderFunction<DataEditModalInstance, Props> = (props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>();
  const [schema, setSchema] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [action, setAction] = useState<string>('EDIT');
  const prepareRef = useRef<(r: any) => any>();
  const [form] = Form.useForm();
  const connection = useConnection();
  const events = useEvents();
  useImperativeHandle(
    ref,
    () => ({
      edit: (
        item: any,
        schema: any,
        title: string,
        url: string,
        prepare?: (record: any) => any,
      ) => {
        setSchema(schema);
        setCurrentItem(item);
        form.resetFields();
        form.setFieldsValue(item);
        setTitle(title);
        setUrl(url);
        setAction('UPDATE');
        prepareRef.current = prepare;
        setVisible(true);
      },
      create: (schema: any, title: string, url: string, prepare?: (record: any) => any) => {
        setSchema(schema);
        setCurrentItem(undefined);
        form.resetFields();
        setTitle(title);
        setUrl(url);
        prepareRef.current = prepare;
        setAction('CREATE');
        setVisible(true);
      },
    }),
    [form],
  );

  const save = useCallback(
    (values: any) => {
      setLoading(true);
      const updates = {
        ...currentItem,
        ...values,
      };
      delete updates.__v;
      (action === 'CREATE' ? connection.post : connection.patch)(
        url,
        prepareRef.current ? prepareRef.current(updates) : updates,
      )
        .then((res) => {
          setVisible(false);
          events.emit(props.type, { action, data: res });
        })
        .catch((reason) => {
          console.error(reason);
          if (!setValidationErrors(form, reason)) {
            message.error('Failed to save');
          }
        })
        .finally(() => setLoading(false));
    },
    [currentItem, action, connection.post, connection.patch, url, events, props.type, form],
  );

  return (
    <Modal
      confirmLoading={loading}
      title={title}
      open={visible}
      width={900}
      onCancel={() => setVisible(false)}
      okText="Save"
      onOk={form.submit}>
      <Form layout="vertical" form={form} onFinish={save}>
        <Form.Items schema={schema} />
      </Form>
    </Modal>
  );
};

export const useDataEditModal = () => useRef<DataEditModalInstance>(null);

export default React.forwardRef<DataEditModalInstance, Props>(DataEditModal);
