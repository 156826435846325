import React, { FC } from 'react';
import { useConnection } from './contexts/Application';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

export const LandingPage: FC = () => {
  const connection = useConnection();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (connection.getToken()) {
  //     navigate('/admin');
  //   } else {
  //     navigate('/login');
  //   }
  // }, [connection, navigate]);
  return (
    <div className="w-full bg-white">
      <div className="home-bg-square w-full min-h-screen flex flex-col items-center">
        <div className="w-full h-full flex flex-col items-center overflow-visible">
          <div className="hidden lg:flex w-full bg-white h-[20vh] absolute bottom-0 flex justify-center items-center">
            {/*<p className="w-full max-w-[1200px] mt-8 p-8 text-center">{`Manage your carbohydrate and calorie intake with the "Carb Counter" app developed by the Sri Lanka College of Endocrinologists (SLCE). Whether you're managing diabetes, maintaining a healthy diet, or simply interested in tracking your nutritional intake, Carb Counter is your essential tool for making informed dietary choices.`}</p>*/}
          </div>
          <div className="w-full lg:max-w-[1400px] p-8 z-10">
            <div className="w-full flex gap-4 items-center justify-center md:justify-between">
              <div className="flex gap-4 items-center">
                <img src={'/square-logo.webp'} className="w-[40px] sm:w-[64px]" />
                <div className="text-white text-[30px]">Carb Counter</div>
              </div>
              <div className="gap-8 items-center hidden md:flex">
                <a className="text-white no-underline text-lg" href="#features">
                  Features
                </a>
                <a className="text-white no-underline text-lg" href="#who-we-are">
                  Who We Are
                </a>
                <a href="#download">
                  <Button type="primary" size="large">
                    Download
                  </Button>
                </a>
              </div>
            </div>
            <div className="flex items-center justify-center lg:justify-between">
              <div className="flex flex-col items-center lg:items-stretch gap-4 pt-8 xl:pt-24">
                <div className="text-white font-bold text-[24px] sm:text-[36px] md:text-[40px] text-center lg:text-left">
                  Carb Counter Mobile App
                </div>
                <div className="text-white text-[16px] sm:text-[24px]  text-center lg:text-left">
                  Get started on your journey to better health
                  <br />
                  with Carb Counter.
                </div>
                <div className="block lg:hidden">
                  <img src="/phones.webp" className="max-h-[250px] sm:max-h-[300px]" />
                </div>
                <div className="text-white font-bold text-[24px] mt-12 hidden md:block">
                  DOWNLOAD THE APP!
                </div>
                <div className="flex flex-col sm:flex-row gap-4">
                  <a href="https://play.google.com/store/apps/details?id=com.sezenta.carbcounter&pcampaignid=web_share">
                    <img src="/android.webp" className="max-w-[200px]" />
                  </a>
                  <a href="https://apps.apple.com/lk/app/carb-counter-slce/id6471596602">
                    <img src="/ios.webp" className="max-w-[200px]" />
                  </a>
                </div>
                <div className="p-4 rounded-2xl bg-white border border-gray-300 border-solid shadow-lg flex gap-4  xl:mt-16">
                  <img src="/slce.webp" className="max-h-[80px] sm:max-h-[120px]" />
                  <img src="/stepd.webp" className="max-h-[80px] sm:max-h-[120px]" />
                  <img src="/life.webp" className="max-h-[80px] sm:max-h-[120px]" />
                </div>
              </div>
              <div className="hidden lg:block">
                <img
                  src="/phones.webp"
                  className=" md:max-h-[300px] lg:max-h-[500px] xl:max-h-[80vh]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#e5edf3]">
        <div className="w-full lg:max-w-[1200px] p-8 py-16">
          <p className="text-xl text-[#034d86] text-center">{`Manage your carbohydrate and calorie intake with the "Carb Counter" app developed by the Sri Lanka College of Endocrinologists (SLCE). Whether you're managing diabetes, maintaining a healthy diet, or simplify interested in tracking your nutritional intake, Carb Counter is your essential tool for making informed dietary choices.`}</p>
        </div>
      </div>
      <div className="w-full flex justify-center" id="features">
        <div className="w-full lg:max-w-[1400px] p-8 py-16">
          <div className="text-[#034d86] text-center text-[36px]">Features</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
            <div className="flex flex-col items-center gap-2">
              <img src="/carbs.webp" className="h-[150px]" />
              <div className="text-[#034d86] text-2xl">Count Carbs</div>
              <div className="text-center text-lg">
                Easily track your carbohydrate intake with our user-friendly interface—perfect for
                staying on top of your health goals
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <img src="/meal.webp" className="h-[150px]" />
              <div className="text-[#034d86] text-2xl">Maintain Meals</div>
              <div className="text-center text-lg">
                Log and save your meals for your later reference, making meal planning a breeze.
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <img src="/diary.webp" className="h-[150px]" />
              <div className="text-[#034d86] text-2xl">Daily Carbs Diary</div>
              <div className="text-center text-lg">
                Keep a daily diary of your carb consumption to monitor your progress and make
                informed dietary choices.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center" id="advanced-features">
        <div className="w-full lg:max-w-[1400px] p-8 py-16">
          <div className="text-[#034d86] text-center text-[36px]">Advanced Features</div>
          <div className="grid grid-cols-1 gap-16">
            <div className="flex flex-col items-center gap-2">
              <img src="/cal.webp" className="h-[150px]" />
              <div className="text-[#034d86] text-2xl">Calculate Insulin</div>
              <div className="text-center text-lg">
                If you are someone with type 1 diabetes on basal bolus insulin regimen,simplify your
                mealtime insulin bolus calculation using our insulin calculators, tailored to your
                meal time carbs, insulin:carb ratios and you blood glucose levels.
                <br />
                <br />
                Please contact you endocrine health care team to enable this feature
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#e5edf3]" id="who-we-are">
        <div className="w-full lg:max-w-[1400px] p-8 py-16 flex flex-col items-center">
          <div className="text-[#034d86] text-center text-[36px]">Who We Are</div>
          <div className="text-lg text-center">
            {`We the StEP-D team of the `}
            <a href="https://endocrinesl.org/">Sri Lanka College of Endocrinologists</a>
            {` with the funding of the Life for Child organization developed this Carb Counter App for people living with diabetes.  This app will especially benefit those living with type 1 diabetes to count carbs and titrate insulin.`}
            <br />
            {`We acknowledge, the Department of Applied Nutrition, Wayamba University of Sri Lanka for providing the nutritional data.`}
          </div>
          <div className="p-4 rounded-2xl bg-white border border-gray-300 border-solid shadow-lg flex gap-4  xl:mt-16">
            <img src="/slce.webp" className="max-h-[80px] sm:max-h-[120px]" />
            <img src="/stepd.webp" className="max-h-[80px] sm:max-h-[120px]" />
            <img src="/life.webp" className="max-h-[80px] sm:max-h-[120px]" />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center" id="download">
        <div className="w-full lg:max-w-[1400px] p-8 py-16 flex flex-col items-center gap-16">
          <div className="text-[#034d86] text-center text-[36px]">Download The App</div>
          <div className="flex flex-col sm:flex-row gap-4">
            <a href="https://play.google.com/store/apps/details?id=com.sezenta.carbcounter&pcampaignid=web_share">
              <img src="/android.webp" className="max-w-[200px]" />
            </a>
            <a href="https://apps.apple.com/lk/app/carb-counter-slce/id6471596602">
              <img src="/ios.webp" className="max-w-[200px]" />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#034d86]">
        <div className="w-full lg:max-w-[1400px] p-8 py-16 flex flex-col gap-4">
          <div className="text-white text-center">
            <a className="text-white no-underline text-lg" href="https://endocrinesl.org">Sri Lanka College of Endocrinologists</a> |{' '}
            <a className="text-white no-underline text-lg" href="https://www.carbcounter.app/privacy-policy">Privacy Policy</a> |{' '}
            <a className="text-white no-underline text-lg" href="https://www.carbcounter.app/terms-and-conditions">Terms and Conditions</a>
          </div>
          <div className="text-white text-center">
            Copyright © {new Date().getFullYear()} Sri Lanka College of Endocrinologists. All rights
            reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
// bg-[#e5edf3]
