import type { FC } from 'react';
import React from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { Admin } from './admin/Admin';
import { ApplicationProvider } from './contexts/ApplicationContext';
import { Empty } from 'antd';
import { LandingPage } from './LandingPage';
import { Login } from './pages/Login/Login';
import { AdminPanelContextProvider } from './pages/Admin/AdminPanelContext';
import { Admin } from './pages/Admin/Admin';
import { AdminUsersPage } from './pages/Admin/AdminUsersPage';
import { MasterDataPage } from './pages/master-data/MasterDataPage';
import { FormConfigProvider } from './components/FormConfigProvider';
import { PrivacyPolicy } from './pages/static/PrivacyPolicy';
import { TermsAndConditions } from './pages/static/TermsAndConditions';
import { DeleteAccount } from './pages/static/DeleteAccount';
import { AdminPatient } from './pages/Admin/AdminPatient';
import { AdminDoctorPage } from './pages/Admin/AdminDoctorPage';

const App: FC = () => (
  <ApplicationProvider>
    <BrowserRouter>
      <FormConfigProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route
            path="/admin"
            element={
              <AdminPanelContextProvider>
                <Admin />
              </AdminPanelContextProvider>
            }>
            <Route path="admin-users" element={<AdminUsersPage />} />
            <Route path="admin-doctors" element={<AdminDoctorPage />} />
            <Route path="admin-patients" element={<AdminPatient />} />
            <Route path="master-data/:id" element={<MasterDataPage />} />
            <Route path="*" element={<Empty />} />
          </Route>
        </Routes>
      </FormConfigProvider>
    </BrowserRouter>
  </ApplicationProvider>
);

export default App;
