import React, { FC, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import superagent from 'superagent';
import jwtDecode from 'jwt-decode';
import events from 'events';
import CommonConfig from '../common/CommonConfig';
import UniversalCookie, { CookieSetOptions } from 'universal-cookie';
// import { v4 as uuid } from 'uuid';
export const AUTH_TOKEN = 'tx-auth-token';
export type RequestMessage = { [key: string]: any };
export type RequestHeader = { [key: string]: string };
export type ApiType = 'put' | 'get' | 'post' | 'patch' | 'delete';

const universalCookie = new UniversalCookie();

export type ConnectionApi = {
  send: <T = any>(
    api: ApiType,
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  post: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  put: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  get: <T = any>(path: string, params?: RequestMessage, headers?: any) => Promise<T>;
  patch: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  delete: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  setToken: (token?: string) => void;
  getToken: () => string;
};

export interface EnumDef {
  id: string;
  type: string;
  name: string;
  tags: string[];
}

export class Enumerations {
  connection: ConnectionApi;
  types: { [type: string]: { [id: string]: EnumDef } } = {};

  constructor(connection: ConnectionApi) {
    this.connection = connection;
  }

  // getAll = async (type: string) => {
  //   try {
  //     if (this.types[type] === undefined) {
  //       const md = await this.connection.get(`admin/masterdata/all/${type}`, {
  //         attributes: ['name', 'tags'],
  //       });
  //       if (md.data.length > 0) {
  //         this.types[type] = Object.fromEntries(md.data.map((d: any) => [d.id, d]));
  //       } else {
  //         return {} as { [id: number]: EnumDef };
  //       }
  //     }
  //     return this.types[type];
  //   } catch (e) {
  //     return {} as { [id: number]: EnumDef };
  //   }
  // };

  // getValue = async (type: string, id: string) => {
  //   if (id === undefined) {
  //     return '';
  //   }
  //   const value = this.types[type]?.[id];
  //   if (value) {
  //     return value;
  //   }
  //   try {
  //     const md = await this.connection.get('admin/masterdata', {
  //       id: [id],
  //       type,
  //       attributes: ['name', 'tags'],
  //     });
  //     if (md.data.length > 0) {
  //       if (!this.types[type]) {
  //         this.types[type] = {};
  //       }
  //       this.types[type][id] = md.data[0].name;
  //     } else {
  //       return `${id}`;
  //     }
  //     return this.types[type][id];
  //   } catch (e) {
  //     return `${id}`;
  //   }
  // };
  //
  // getValues = async (type: string, ids: number[]) => {
  //   const notFound = ids.filter((id) => this.types[type]?.[id] === undefined);
  //   if (notFound.length === 0) {
  //     return ids.map((id) => ({ id, name: this.types[type]?.[id] }));
  //   }
  //   try {
  //     const md = await this.connection.get('admin/masterdata', {
  //       id: ids,
  //       type,
  //       attributes: ['id', 'name'],
  //     });
  //     for (const d of md.data) {
  //       if (!this.types[type]) {
  //         this.types[type] = {};
  //       }
  //       this.types[type][d.id] = d.name;
  //     }
  //     return ids.map((id) => ({ id, name: this.types[type]?.[id] }));
  //   } catch (e) {
  //     return [];
  //   }
  // };
}

export type User = {
  userId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
};
const ApplicationContext = React.createContext<{
  connection?: ConnectionApi;
  user?: User;
  enums: Enumerations;
  events: events.EventEmitter;
}>({} as any);

export const ApplicationProvider: FC<PropsWithChildren> = (props) => {
  const [token, setToken] = useState<string | undefined>(universalCookie.get(AUTH_TOKEN));
  const evts = useRef(new events.EventEmitter()).current;

  const getUserInfo = useCallback((tok?: string) => {
    if (tok) {
      try {
        const user: any = jwtDecode(tok);
        const admin = user.admin;
        return {
          userId: admin._id,
          firstName: admin.firstName,
          lastName: admin.lastName,
          email: admin.email,
          fullName: `${admin.firstName} ${admin.lastName}`,
        };
      } catch (e) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, []);

  const [user, setUser] = useState<User | undefined>(getUserInfo(token));

  const sendRequest = useCallback(
    (
      method: (url: string) => superagent.SuperAgentRequest,
      path: string,
      msg?: RequestMessage,
      query?: RequestMessage,
      headers?: RequestHeader,
    ) => {
      return new Promise((resolve, reject) => {
        console.log('API_BASE=', CommonConfig.baseUrl);
        const req = method(`${CommonConfig.baseUrl}/${path}`).set(
          'Content-Type',
          'application/json',
        );
        if (query) {
          req.query(query);
        }
        if (token !== undefined && token !== null) {
          req.set('Authorization', `Bearer ${token}`);
        }
        if (headers) {
          Object.keys(headers).forEach((key) => req.set(key, headers[key]));
        }

        let jsonString = '';
        try {
          jsonString = JSON.stringify(msg);
        } catch (e) {
          throw Error('Invalid JSON object');
        }
        req.timeout(30000);
        req
          .send(jsonString)
          .then((res: any) => {
            resolve(res.body);
          })
          .catch((res: any) => {
            console.error('Error', JSON.stringify(res, null, 2));
            if (res.response) {
              const { response } = res;
              if (response.statusCode) {
                const { status, statusText } = response;
                if (res.response.body?.message) {
                  reject({
                    status,
                    statusText: statusText ?? `${status}`,
                    message: res.response.body?.message,
                    body: res.response.body,
                  });
                } else {
                  reject({
                    status,
                    statusText: statusText ?? `${status}`,
                    message: statusText ? statusText : 'Unknown',
                    body: res.response.body,
                  });
                }
              } else {
                reject({
                  statusCode: 500,
                  statusText: 'Unknown',
                  code: 'Unknown',
                  message: 'Unknown',
                });
              }
            } else {
              reject({
                statusCode: 500,
                statusText: res.code === 'ECONNABORTED' ? 'Request timeout' : 'Connection error',
                message: ['Failed to connect'],
                body: { message: ['Failed to connect'] },
              });
            }
          });
      }) as any;
    },
    [token],
  );

  const post = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.post, path, msg, query, headers);
    },
    [sendRequest],
  );
  const put = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.put, path, msg, query, headers);
    },
    [sendRequest],
  );
  const get = useCallback(
    (path: string, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.get, path, undefined, query, headers);
    },
    [sendRequest],
  );
  const patch = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.patch, path, msg, query, headers);
    },
    [sendRequest],
  );
  const del = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.delete, path, msg, query, headers);
    },
    [sendRequest],
  );

  const send = useCallback(
    (api: ApiType, path: string, msg?: RequestMessage, params?: RequestMessage, headers?: any) => {
      switch (api) {
        case 'post':
          return post(path, msg, params, headers);
        case 'get':
          return get(path, params ?? msg, headers);
        case 'put':
          return put(path, msg, params, headers);
        case 'patch':
          return patch(path, msg, params, headers);
        case 'delete':
          return del(path, msg, params, headers);
      }
    },
    [del, get, post, put, patch],
  );

  const updateToken = useCallback(async (tok?: string) => {
    const options: CookieSetOptions = { path: '/', domain: CommonConfig.cookieDomain };
    setToken(tok);
    if (tok) {
      universalCookie.set(AUTH_TOKEN, tok, options);
    } else {
      universalCookie.remove(AUTH_TOKEN, options);
    }
  }, []);
  const getToken = useCallback(() => {
    if (token) {
      return token;
    } else {
      return '';
    }
  }, [token]);
  const connection: ConnectionApi = useMemo(
    () => ({
      send,
      post,
      put,
      get,
      patch,
      delete: del,
      setToken: updateToken,
      getToken,
    }),
    [send, post, put, get, patch, del, updateToken, getToken],
  );
  const enums = useMemo(() => new Enumerations(connection), [connection]);
  return (
    <ApplicationContext.Provider
      value={{
        connection,
        user,
        enums,
        events: evts,
      }}>
      {props.children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;
